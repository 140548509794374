@import '../../variables.scss';


.login{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: black;
}
.wrapper{
    height: 100vh;
    width: 100vw;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2{
        margin: 5vmin 0;
        padding: 0;
        color: white;
    }
    input[type="text"]{
        margin: 5vmin 0;
        width: 15vw;
    }
    button{
        margin: 5vmin 0;
    }
}