.modal{
    box-sizing: border-box;
    padding: 5vmin;
    position: absolute;
    width: 100vw;
    height: 90vh;
    left: 0;
    top:0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: rgba(0,0,0,.5);
}

.hidden{
    display: none;
}