$negro: #211A1E;
$blanco: white;
$rojo: #A4031F;
$itemColor: #C6B38E;
$gris: #C6B38E;

body{
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column-reverse;
    width: 100vw;
    min-width: 100%;
    height: 100vh;
}

.cuerpo{
    background: $gris;
    width: 100vw;
    height: 90vh;
    overflow: hidden;
}
.wrapper{
    width: 80vw;
    margin-left: 5vw;
    height: auto;
    display: grid;
    grid-template-columns: [main-start] 1fr [content-start] 1fr [content-end] 1fr [main-end];
    grid-template-rows: [main-start] 10vw [content-start] 30vh [content-end] calc(70vh - 10vw) [main-end];
}
button{
    width: 20vw;
    height: 6vh;
    background: $rojo;
    color: $blanco;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 1vh;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
    cursor: pointer;
    &:hover{
        background: lighten($color: $rojo, $amount: 10%);
    }
    &:active,&:focus{
        outline: none;
        background: darken($color: $rojo, $amount: 10%);
    }
}

.botonera{
    grid-column-start: main-start;
    grid-column-end: content-end;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    button{
        margin-right: 2vmin;
    }
}

.listaBaja{
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 10vw);
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
    .cabecera{
        box-sizing: border-box;
        height: 20%;
        padding: 1vmin;
        background: $negro;
        color: white;
        select{
            width: 50%;
            height: 5vh;
            margin: 2.8vmin;
        }
        h2{
            margin: 2.8vmin;
        }
        .fila{
            width: calc(100% - 1vw);
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            h4{
                margin: 0;
                padding: 0;
            }
        }
    }
    .cuerpoLista{
        width: 100%;
        height: 80%;
        background: $blanco;
        overflow: hidden;
        overflow-y: scroll;
        .itemHorario{
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 1vmin;
            height: 5vh;
            &:nth-child(2n+1){
                background: lightgray;
            }
        }
        .item{
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: stretch;
            width: 100%;
            padding: 0 1vmin;
            height: 5vh;
            &:nth-child(2n+1){
                background: lightgray;
            }
            .td{
                width: 33.3%;
                p{
                    padding: 0;
                    margin: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 90%;
                }
            }
            .tdCenter{
                width: 33.3%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .tdButton{
                width: 33.3%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
            button{
                width: 4vh;
                height: 4vh;
            }
        }
    }
}
.formulario{
    width: 90vw;
    height: 60vh;
    background: $blanco;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
    .fila{
        width: 60vw;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2vmin;
        i{
            width: 2vw;
        }
        button:first-child{
            margin-right: 2vw;
        }
        input,select{
            width: 100%;
        }
    }
    .busqueda{
        width: 60vw;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2vmin;
        i{
            width: 2vw;
        }
        input,select{
            width: 100%;
        }
        button{
            width: 4vw;
            margin-left: 1vw;
        }
    }
}

.ocultar{
    display: none;
}