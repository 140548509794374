@import '../../variables.scss';

.lista{
    height: 25vh;
    width: 90vw;
    .titular{
        box-sizing: border-box;
        background: black;
        color: white;
        height: 5vh;
        padding: 2vmin;
        h3{
            margin: 0;
            padding: 0;
        }
    }
    .cuerpoLista{
        background: white;
        height: 20vh;
        overflow: hidden;
        overflow-y: scroll;
    }
}
