@import '../../variables.scss';

.contenedor{
    width: 80vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    .fila{
        box-sizing: border-box;
        height: 8vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: black;
        color: white;
        padding: 2vmin 1vmin;
        input{
            box-sizing: border-box;
            width: 40vw;
            height: 5vh;
            margin: 0vh 2vmin;
        }
    }
    .lista{
        height: 50vh;
        width: 100%;
        overflow: hidden;
        overflow-y: scroll;
        background: white;
    }
}
