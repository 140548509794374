@import '../../variables.scss';

.contenedor{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-width: 100%;
    height: 100vh;
    
}

.botonera{
    box-sizing: border-box;
    width: 100vw;
    max-width: 100%;
    height: 18vh;
    margin: 5vmin 5vmin;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    button{
        margin: 2vmin;
        width: 38vw;
    }
}
.listas{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    min-width: 100%;
    height: 65vh;
}
.divisor{
    height: 5vh;
    width: 100%;
}

